import i18n from '@/i18n'
import { Question, ReportType } from '@/shared/model/report.model'

export function initialReports () {
  return [
    {
      type: ReportType.FREISCHALTUNG,
      questions: [
        {
          subject: i18n.t('r.measuring-instrument-functionality').toString(),
          question: i18n.t('r.the-tester-accepts-the-security-instructions').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
        {
          subject: i18n.t('r.positive-test-done').toString(),
          question: i18n.t('r.positive-test-done').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
        {
          subject: i18n.t('r.measuring-instrument-functionality').toString(),
          question: i18n.t('r.measuring-instrument-tested-at-reference-source').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
        {
          question: i18n.t('r.voltage-between-hv-plus-and-chassis').toString(),
          answer: '',
          valid: false,
          unit: 'V',
          readonly: true,
        }, // Positive if <1V
        {
          question: i18n.t('r.voltage-between-hv-minus-and-chassis').toString(),
          answer: '',
          valid: false,
          unit: 'V',
          readonly: true,
        }, // Positive if <1V
        {
          question: i18n.t('r.voltage-between-hv-plus-and-hv-minus').toString(),
          answer: '',
          valid: false,
          unit: 'V',
          readonly: true,
        }, // Positive if <1V
        {
          subject: i18n.t('r.measuring-instrument-functionality').toString(),
          question: i18n.t('r.measuring-instrument-tested-at-reference-source').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
      ] as Question[],
      infos: [
        i18n.t('r.further-voltage-measurements-if-needed').toString(),
        i18n.t('r.measurements-ok-if-smaller-than-1-v').toString(),
      ],
    },
    {
      type: ReportType.ISOLATIONSWIDERSTAND,
      questions: [
        {
          subject: i18n.t('r.measuring-instrument-functionality').toString(),
          question: i18n.t('r.the-tester-accepts-the-security-instructions').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
        {
          subject: i18n.t('r.measuring-instrument-functionality').toString(),
          question: i18n.t('r.measuring-instrument-tested').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
        {
          subject: i18n.t('r.absence-of-voltage').toString(),
          question: i18n.t('r.absence-of-voltage-confirmed').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
        {
          subject: i18n.t('r.voltage').toString(),
          question: i18n.t('r.used-test-voltage').toString(),
          answer: '',
          valid: false,
          unit: 'V',
          selectionForAnswer: ['250', '300', '400', '500', '1000'],
          readonly: true,
        },
        {
          subject: i18n.t('r.hv-system-voltage').toString(),
          question: i18n.t('r.hv-system-voltage').toString(),
          answer: '',
          valid: false,
          unit: 'V',
          modifier: true,
          selectionForAnswer: ['250', '300', '400', '500', '1000'],
          readonly: true,
        },
        {
          subject: i18n.t('r.resistance-per-volt').toString(),
          question: i18n.t('r.isolation-standard').toString(),
          answer: '',
          valid: false,
          unit: 'Ohm/V',
          selectionForAnswer: ['100', '500', '750', '1000'],
          readonly: true,
        },
        {
          question: i18n.t('r.isolation-resistance-between-hv-plus-and-chassis').toString(),
          answer: '',
          valid: false,
          unit: 'KΩ',
          readonly: true,
        }, // Positive if >100Ohm/V
        {
          question: i18n.t('r.isolation-resistance-between-hv-minus-and-chassis').toString(),
          answer: '',
          valid: false,
          unit: 'KΩ',
          readonly: true,
        }, // Positive if >100Ohm/V of question[1]
      ] as Question[],
      infos: [i18n.t('r.further-measurements-if-needed').toString()],
    },
    {
      type: ReportType.POTENTIALAUSGLEICH,
      questions: [
        {
          subject: i18n.t('r.measuring-instrument-functionality').toString(),
          question: i18n.t('r.the-tester-accepts-the-security-instructions').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
        {
          subject: i18n.t('r.measuring-instrument-functionality').toString(),
          question: i18n.t('r.measuring-instrument-tested').toString(),
          answer: '',
          valid: false,
          unit: 'boolean',
          readonly: true,
        }, // Positive if done
        {
          subject: i18n.t('r.test-current').toString(),
          question: i18n.t('r.test-current-q').toString(),
          answer: '',
          valid: false,
          unit: 'A',
          selectionForAnswer: ['0,2', '0,5', '1'],
          readonly: true,
        }, // Positive if done
        {
          subject: i18n.t('r.measuring-point').toString(),
          question: i18n.t('r.measuring-point-chassis-vs').toString(),
          answer: '',
          valid: false,
          unit: 'Ohm',
          selectionForQuestion: [i18n.t('r.converter-housing')],
          readonly: true,
        }, // Positive if <0,1 Ohm
      ] as Question[],
      infos: [i18n.t('r.further-measurements-if-needed').toString()],
    },
  ]
}
