import { ICompany } from './company.model'
import { ICompanyVehicle } from './companyVehicle.model'
import { IUser } from './user.model'

export enum ReportType {
  FREISCHALTUNG = 'FREISCHALTUNG',
  ISOLATIONSWIDERSTAND = 'ISOLATIONSWIDERSTAND',
  POTENTIALAUSGLEICH = 'POTENTIALAUSGLEICH',
}

export interface Question {
  subject?: string
  question: string
  answer?: string
  valid?: boolean
  unit?: string
  modifier?: boolean
  selectionForAnswer?: string[]
  selectionForQuestion?: string[]
  selectedQuestion?: string
  readonly?: boolean
}

export interface IReport {
  _id?: string
  name?: string
  date?: Date
  company?: string
  companyObject?: ICompany
  companyVehicle?: string
  companyVehicleObject?: ICompanyVehicle
  user?: string
  userObject?: IUser
  type?: ReportType
  language?: string
  infos?: string[]
  questions?: Question[]
  pdfFile?: string
}

export class Report implements IReport {
  constructor (public name: string = '', public date: Date = new Date(), public pdfFile = '') {
    return this
  }
}

export interface selectionForAnswerExtension {
  inputId?: string
  unit: string
  selectionForAnswer: string[]
}
