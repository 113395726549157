export interface ICompanyVehicle {
  _id?: string
  manufacturer?: string
  vehicle?: string
  owner?: string
  vin?: string
  sn?: string
  licencePlate?: string
  constructionYear?: string
  mileage?: string,
  createdAt?: Date
  updatedAt?: Date
}

export class CompanyVehicle implements ICompanyVehicle {
  constructor (
    public _id?: string,
    public manufacturer?: string,
    public vehicle?: string,
    public owner?: string,
    public vin?: string,
    public sn?: string,
    public licencePlate?: string,
    public constructionYear?: string,
    public mileage?: string,
  ) {
    return this
  }
}
