











































































































































































































import { IVehicle, Vehicle } from '@/shared/model/vehicle.model'
import { ICompanyVehicle, CompanyVehicle } from '@/shared/model/companyVehicle.model'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import {
  IReport,
  Question,
  Report,
  ReportType,
  selectionForAnswerExtension,
} from '@/shared/model/report.model'
import reportService from '@/shared/services/reportService'
import axios from 'axios'
import _ from 'lodash'
import { regexEUNumber, screenSizeIsMobile } from '@/shared/helpers'
import { initialReports } from './initialReports'

export default Vue.extend({
  name: 'ReportWizard',

  components: {
    CompanyVehiclesTable: () => import('../vehicle/CompanyVehiclesTable.vue'),
  },
  data () {
    return {
      selectedCompanyVehicle: new CompanyVehicle(),

      tab: 0,
      tabs: [this.$t('vehicle').toString(), this.$t('report').toString(), this.$t('inputs').toString()],
      availableSteps: [0],
      availableReports: [ReportType.FREISCHALTUNG, ReportType.ISOLATIONSWIDERSTAND, ReportType.POTENTIALAUSGLEICH],

      initialReports: initialReports(),

      questions: [] as Question[],

      report: new Report() as IReport,

      searchInput: [] as string[],
      answerSearchInput: [] as string[],
      selectionForQuestionFromLS: [] as string[],
      selectionForAnswerFromLS: [] as selectionForAnswerExtension[],
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      vehicles: 'companyVehicleStore/getList',
      vehicleManufacturers: 'vehicleManufacturerStore/getList',
      company: 'company',
      createReportError: 'reportStore/getError',
    }),
    isMobile () {
      return screenSizeIsMobile()
    },
  },
  created () {
    this.getSelectionForQuestionFromLocalstorage()
    this.getSelectionForAnswerFromLocalstorage()
    const companyVehicleId = this.$route.params.companyVehicleId

    if (companyVehicleId) {
      const companyVehicle = this.vehicles.find((x: ICompanyVehicle) => x._id === companyVehicleId)
      if (companyVehicle) {
        this.handleSelectVehicle(companyVehicle)
      }
    }
  },
  methods: {
    ...mapActions('reportStore', {
      createReport: 'createItem',
    }),
    async next (valid: any) {
      if (this.tab === this.tabs.length - 1) {
        await this.clickSaveReport()
      } else {
        this.tab++
      }
    },
    autocompleteFilter (item: any, queryText: string, itemText: string) {
      return queryText
    },
    handleSelectVehicle (vehicle: ICompanyVehicle) {
      this.selectedCompanyVehicle = vehicle
      this.availableSteps.push(1)
      this.next(true)
    },
    handleSelectReport (type: ReportType) {
      const report = this.initialReports.find((x) => x.type === type)
      if (report) {
        this.report = _.cloneDeep(report)
      }

      if (this.report && this.report.questions) {
        for (const q of this.report.questions) {
          if (q.selectionForQuestion) {
            q.selectionForQuestion.push(...this.selectionForQuestionFromLS)
          }
          if (q.selectionForAnswer && q.unit) {
            const sfa = this.selectionForAnswerFromLS.find((x) => x.unit === q.unit)
            if (sfa && sfa.selectionForAnswer) {
              q.selectionForAnswer.push(...sfa.selectionForAnswer)
            }
          }
        }
      }

      this.availableSteps.push(2)
      this.next(true)
    },
    normalizeNumber (question: any) {
      if (regexEUNumber(question.answer) && question.answer.length) {
        const formattedInput = Math.abs(parseFloat(question.answer.replace(',', '.')))
          .toString()
          .replace('.', ',')

        question.answer = formattedInput
      }
    },
    createNewAnswerItem (question: Question, index: number) {
      if (this.answerSearchInput && this.answerSearchInput[index] && this.answerSearchInput[index].length) {
        const input = this.answerSearchInput[index]
        if (regexEUNumber(input)) {
          const formattedInput = Math.abs(parseFloat(input.replace(',', '.')))
            .toString()
            .replace('.', ',')

          let foundSelectionForAnswer = this.selectionForAnswerFromLS.find((x) => x.unit === question.unit)
          if (!foundSelectionForAnswer) {
            const unit = question.unit ? question.unit : ''
            foundSelectionForAnswer = {
              unit: unit,
              selectionForAnswer: [],
            }
            this.selectionForAnswerFromLS.push(foundSelectionForAnswer)
          }
          if (!foundSelectionForAnswer.selectionForAnswer.includes(formattedInput)) {
            foundSelectionForAnswer.selectionForAnswer.push(formattedInput)
            localStorage.setItem('selectionForAnswer', JSON.stringify(this.selectionForAnswerFromLS))
          }
          if (question.selectionForAnswer && !question.selectionForAnswer.includes(formattedInput)) {
            question.selectionForAnswer.push(formattedInput)
          }
          if (this.report && this.report.questions) {
            this.report.questions[index].answer = formattedInput
          }
        } else {
          this.$toast.error(this.$t('dialog.you-can-only-input-eu-numbers').toString())
        }
      }
    },
    createNewQuestionItem (question: Question, index: number) {
      if (this.searchInput) {
        if (!this.selectionForQuestionFromLS.includes(this.searchInput[index])) {
          this.selectionForQuestionFromLS.push(this.searchInput[index])
          localStorage.setItem('selectionForQuestion', JSON.stringify(this.selectionForQuestionFromLS))
        }
        if (question.selectionForQuestion && !question.selectionForQuestion.includes(this.searchInput[index])) {
          question.selectionForQuestion.push(this.searchInput[index])
        }
      }
    },
    getSelectionForQuestionFromLocalstorage () {
      const selectionForQuestionFromLS = localStorage.getItem('selectionForQuestion')
      if (selectionForQuestionFromLS) {
        const arr: string[] = JSON.parse(selectionForQuestionFromLS)
        if (arr.length) {
          this.selectionForQuestionFromLS = arr
        }
      }
    },
    getSelectionForAnswerFromLocalstorage () {
      const selectionForAnswerFromLS = localStorage.getItem('selectionForAnswer')
      if (selectionForAnswerFromLS) {
        const arr: selectionForAnswerExtension[] = JSON.parse(selectionForAnswerFromLS)
        if (arr.length) {
          this.selectionForAnswerFromLS = arr
        }
      }
    },

    clickAddQuestion () {
      if (this.report && this.report.questions) {
        const mod = this.report.type === ReportType.FREISCHALTUNG ? 1 : 0

        const questionToCopy = this.report.questions[this.report.questions.length - 1 - mod]
        const newQuestion = {
          question: questionToCopy.question,
          subject: questionToCopy.subject,
          unit: questionToCopy.unit,
          selectionForAnswer: questionToCopy.selectionForAnswer,
          selectionForQuestion: questionToCopy.selectionForQuestion,
        }

        if (mod) {
          this.report.questions.splice(-1, 0, newQuestion)
        } else {
          this.report.questions.push(newQuestion)
        }
      }
    },

    clickRemoveQuestion (item: any) {
      if (this.report && this.report.questions) {
        const index = this.report.questions.findIndex(x => x === item)
        if (index !== -1) {
          this.report.questions.splice(index, 1)
        }
      }
    },

    allQuestionsAnswered () {
      let result = true
      if (this.report.questions) {
        for (let i = 0; i < this.report.questions.length - 1; i++) {
          const question = this.report.questions[i]
          if (question.unit === 'boolean') {
            if (!question.answer) result = false
          } else {
            if (!question.answer || !question.answer.length) {
              result = false
            }
          }
        }
      }
      return result
    },
    async clickSaveReport () {
      this.loading = true
      let idxOfTestVoltage, valOfTestVoltage;
      if (this.report && this.report.questions && this.report.type) {
        // this.report.name = `Report from ${new Date().getFullYear()} - ${new Date().getMonth() +
        //   1} - ${new Date().getDate()}`
        this.report.name = `${this.$t('report-from')} ${new Date()}`
        this.report.date = new Date()
        this.report.companyVehicle = this.selectedCompanyVehicle._id
        this.report.companyVehicleObject = this.selectedCompanyVehicle
        this.report.company = this.company._id
        this.report.companyObject = this.company
        this.report.user = this.account._id
        this.report.userObject = this.account
        this.report.language = this.account.language || 'de'

        let m1, m2, modifier
        for (const question of this.report.questions) {
          if (question.selectedQuestion) {
            question.question += ' ' + question.selectedQuestion
          }
          if (m1 && m2) {
            modifier = m1 * m2
          }
          if (this.report.type === ReportType.ISOLATIONSWIDERSTAND && question.modifier && question.answer) {
            m1 = parseFloat(question.answer)
          }
          if (this.report.type === ReportType.ISOLATIONSWIDERSTAND && question.unit === 'Ohm/V' && question.answer) {
            m2 = parseFloat(question.answer)
          }
          // extract the test voltage
          if (this.report.type === ReportType.ISOLATIONSWIDERSTAND && question.question === 'Angewendete Prüfspannung' && question.answer) {
            idxOfTestVoltage = this.report.questions.findIndex(x => x.question === 'Angewendete Prüfspannung')
            valOfTestVoltage = question.answer
          }
          // now check if the test voltage is lower than the system voltage, if so it is invalid
          if (this.report.type === ReportType.ISOLATIONSWIDERSTAND && question.question === 'HV-Betriebsspannung / HV-Systemspannung' && question.answer) {
            if (idxOfTestVoltage && valOfTestVoltage && parseInt(valOfTestVoltage) <= parseInt(question.answer)) {
              this.report.questions[idxOfTestVoltage].valid = false
            }
          }
          question.valid = reportService.answerIsValid(this.report.type, question, modifier)
        }

        await axios
          .post('/api/report', this.report)
          .then((result) => {
            if (result && result.data && result.data._id) {
              this.$toast(this.$t('dialog.report-save-successfully').toString())
              this.$emit('wizardfinished')
              this.$router.push(`/app/reports/${result.data._id}`)
            }
            this.loading = false
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 400) {
              this.$toast.error(this.$t('dialog.report-already-exists').toString())
            } else {
              this.$toast.error(this.$t('dialog.an-unexpected-error-occured').toString())
            }
            this.loading = false
          })
      }
    },
  },
})
