import { types } from '@/store/utils/resource'
import axios from 'axios'
import { Question, ReportType } from '../model/report.model'

class ReportService {
  answerIsValid(type: ReportType, question: Question, modifier: number | undefined = undefined) {
    if (this.getFunctionByString[type]) {
      if (modifier) {
        return this.getFunctionByString[type](question, modifier)
      } else {
        return this.getFunctionByString[type](question)
      }
    }

    throw new Error(`Method '${type}' is not implemented.`)
  }

  getFunctionByString: { [K: string]: Function } = {
    FREISCHALTUNG: this.processFreischaltung,
    ISOLATIONSWIDERSTAND: this.processIsolationswiderstand,
    POTENTIALAUSGLEICH: this.processPotentialausgleich,
  }

  processFreischaltung(question: Question) {
    switch (question.unit) {
      case 'boolean':
        return !!question.answer
      case 'V':
        if (question.answer) {
          return parseFloat(question.answer) < 1
        } else {
          return false
        }
    }
  }

  processIsolationswiderstand(question: Question, modifier: number) {
    switch (question.unit) {
      case 'boolean':
        return !!question.answer
      case 'V':
        return true
      case 'Ohm/V':
        return true
      case 'KΩ':
        if (question.answer) {
          // because KΩ we multiply with 1000
          const answer = parseFloat(question.answer.replace(',', '.')) * 1000
          return answer >= modifier
        } else {
          return false
        }
    }
    return false
  }

  processPotentialausgleich(question: Question) {
    switch (question.unit) {
      case 'A':
        if (question.answer) {
          return parseFloat(question.answer.replace(',', '.')) >= 0.2
        } else {
          return false
        }
      case 'Ohm':
        if (question.answer) {
          return parseFloat(question.answer.replace(',', '.')) <= 0.1
        } else {
          return false
        }
      case 'boolean':
        return !!question.answer
    }
    return false
  }

  async getReportsAsAdmin(store: any, { l, lo, sf, so, day, search = {} }: any) {
    store.commit(`reportStore/${types.GET_LIST.REQUEST}`, {
      l,
      lo,
      day,
      search,
    })
    try {
      const result = await axios.get('/api/reportAsAdmin/', {
        params: { l: l, lo: lo - 1, sf, so, day, ...search },
      })
      store.commit(`reportStore/${types.GET_LIST.SUCCESS}`, result)
    } catch (err) {
      console.log(err)
      store.commit(`reportStore/${types.GET_LIST.FAILURE}`)
    }
  }

  async getReportsAsAdminByCompany(store: any, companyID: string, { l, lo, sf, so, day, search = {} }: any) {
    store.commit(`reportStore/${types.GET_LIST.REQUEST}`, {
      l,
      lo,
      day,
      search,
    })
    try {
      const result = await axios.get(`/api/reportAsAdmin/${companyID}`, {
        params: { l: l, lo: lo - 1, sf, so, day, ...search },
      })
      store.commit(`reportStore/${types.GET_LIST.SUCCESS}`, result)
    } catch (err) {
      console.log(err)
      store.commit(`reportStore/${types.GET_LIST.FAILURE}`)
    }
  }

  async getReportsByUserUUID(store: any, { l, lo, sf, so, day, search = {} }: any, uuid: string) {
    store.commit(`reportStore/${types.GET_LIST.REQUEST}`, {
      l,
      lo,
      day,
      search,
    })
    try {
      const result = await axios.get(`/api/reportByUser/${uuid}`, {
        params: { l: l, lo: lo - 1, sf, so, day, ...search },
      })
      store.commit(`reportStore/${types.GET_LIST.SUCCESS}`, result)
    } catch (err) {
      console.log(err)
      store.commit(`reportStore/${types.GET_LIST.FAILURE}`)
    }
  }

  async getReportsForDashboard(store: any, { l, lo, sf, so, day, search = {} }: any) {
    store.commit(`reportStore/${types.GET_LIST.REQUEST}`, {
      l,
      lo,
      day,
      search,
    })
    try {
      const result = await axios.get('/api/statistics/reports/table', {
        params: { l: l, lo: lo - 1, sf, so, day, ...search },
      })
      store.commit(`reportStore/${types.GET_LIST.SUCCESS}`, result)
    } catch (err) {
      console.log(err)
      store.commit(`reportStore/${types.GET_LIST.FAILURE}`)
    }
  }
}

const reportService = new ReportService()
export default reportService
